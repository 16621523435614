.footer {
    background-color: #212529;
   

}

.footer_container {
    
    margin: 5vh 10vw 5vh 10vw;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

/* .footer_text{
    color: white;
    font-size: 20px;
   
   

} */
.footer_row_address {
    flex: 1;
    color: white;
    border-radius: 30px;
    padding: 20px 20px 20px 20px;
}

.footer_row_image {
    padding: 20px 20px 20px 20px;
    flex: 1;
    object-fit: cover;
    width: 300px;
    height: 200px;
    border-radius: 30px;
    
    overflow: hidden;
}
.copyRights{
   
  border-top: 1px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 10px 0px 10px 0px; */
}
.copyRights > a {
    color: white;
    margin-left: 6px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    
}
.footer_row_goggleMap {
    flex: 1;

    border-radius: 30px;
    padding: 20px 20px 20px 20px;
}
.MapContainer {
    border-radius: 30px;
    padding: 20px 20px 20px 20px;
    align-items: end;
        overflow: hidden;
        }
        
@media screen and (max-width: 800px) {

    

    .footer_container {
    margin: 5vh 10vw 5vh 10vw;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
   
}
     
.footer_text{
   text-align: center;
    color: white;
    font-size: 16px;
    /* overflow-wrap: break-word; */
   

}
.footer_row_address {
    
    align-items: center;
    color: white;
    border-radius: 30px;
    padding: 20px 20px 20px 20px;
}

  }