.cloth {
    background-color: whitesmoke;
    align-items: center;
}

.cloth_products {}

.cloth_heading {
    margin: 10px 0px 10px 0px;
    text-align: center;
    color: rgb(27, 27, 27);
    font-size: 20px;
    font-weight: 700;
}