.card{
  /* background-color: rgb(70, 226, 43); */
  width: 99%;
  height: 100%;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: whitesmoke; */
}

.cardBody{
  padding: 30px;
  /* background-color: rgb(255, 0, 255); */
  
  object-fit: contain;
  border-radius: 50px;
 max-width: 100%;
 max-height: 100%;
   
  display: flex;
  flex:1;
  
}


.cardImage{
  
  /* background-color: rgb(223, 0, 0); */
  
  border-radius:60px  ;  
 
  object-fit: contain;
  width: 100%;
  height: 100%;
 max-width: 100%;
 max-height: 100%;
  flex: 0.7;
    /* display: flex; */
    /* justify-content: center; 
    align-items: center; */
} 

.cardImage > img{
  
  border-radius: 30px 30px 30px 30px ;  
  
  max-width: 100%; /* Ensure the image takes up the full width of its container */
  height: 100%; /* Maintain aspect ratio */
     
  object-fit: cover; /* Ensure the image covers the entire container */
}

.cardDetails{
flex:.5;
  margin: 30px;
  text-align: center;
  display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
  /* background-color: rgb(174, 174, 254); */
}

.cardDetailsTitle{
  /* text-align: center; */
  font-size: 25px;
  font-weight: bold;
}
.cardDetailsCost{
  padding: 5px;
  font-size: 18px;
  font-weight:400;
}
.cardDetailsDescription{
  padding: 5px 10px 10px 5px;
  text-align: justify;
  font-size: 18px;
  font-weight:400;
  

}

@media (max-width:780px) {
  .cardBody{ 
    padding: 10px;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  
  }

  .cardImage{
    width: 80%;
  height: 70%;
 max-width: 70%;
 max-height: 60%;
  }
  .cardDetails{
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
  }
  .cardDetailsTitle{
    /* text-align: center; */
    font-size: 15px;
    font-weight: bold;
   
  }
  .cardDetailsDescription{
    padding: 5px 10px 10px 5px;
    text-align: justify;
    font-size: 12px;
    /* font-weight:10px; */
  }
  .cardDetailsCost{
    padding: 0px;
    font-size: 12px;
    font-weight:400;
  }
  .cardImage > img{
    object-fit: cover;
  }
}