.CustomRadioButton{
    border-radius: 30px;
    background: whitesmoke;
    box-shadow: 15px 15px 30px #bebebef2,
               -15px -15px 30px rgb(255, 255, 255);
}


.radio-inputs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #EEE;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
  }
  
  .radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .radio-inputs .radio input {
    display: none;
  } 
  
  .radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: .5rem 0;
    color: rgba(51, 65, 85, 1);
    transition: all .15s ease-in-out;
  }
  
  .radio-inputs .radio input:checked + .name {
    background-color: #fff;
    font-weight: 600;
  }

  .name{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }

  @media (max-width: 600px){
    .name{
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
    }
  }

  @media (max-width: 320px){
    .radio-inputs {
      width: 230px;
    }

   
  }