.customBtn {
    transition: all 0.3s ease-in-out;
    /* font-family: "Dosis", sans-serif; */
  }
  
  .customBtn {
    width: 150px;
    height: 40px;
    border-radius: 30px;
    background-image: linear-gradient(135deg, rgb(196, 146, 254) 0%, #007bff 100%);
    box-shadow: 0 15px 20px -6px rgba(84, 164, 255, 0.5);
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 20px;
    color: white;
  }
  
  .customBtn:hover {
    transform: translateY(3px);
    box-shadow: none;
    color: white;
  }
  
  .customBtn:active {
    opacity: 0.5;
  }