.content{
    margin: 40px 1vw 40px 1vw;
    /* background-color: blue; */
}

.contentHead{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px ;
    /* margin: 0px 15vw 0px 15vw; */
    
}
.contentHead > button{
    border: none;
    font-size: 20px;
    font-weight: bold;
    background-color: whitesmoke;
  
}

.contentMid{
    /* background-color: aquamarine; */
}


