.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(66, 66, 66, 0.736);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20vh 20vw; */
    z-index: 99;
    backdrop-filter: blur(5px);
}

.popup-content {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blueviolet; */
    height: 80vh;
    /* height: auto; */
    width: 70vw;
   
    border-radius: 50px;
   
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/* 
@media (min-aspect-ratio: 16/9) and (max-width: 1200px) {
   
    .popup {
     
    }
  }
   */

   