.FloatingCard {
    /* margin: 50px; */
    margin: 40px;
    width: 250px;
    height: 600px;
    border-radius: 30px;
    background: #f5f5f5;
    box-shadow: 15px 15px 30px #bebebef2,
               -15px -15px 30px rgb(255, 255, 255);
  }

  .FloatingCard > img{
    background-color: white;
    width: 100%; /* Ensure the image takes up the full width of its container */
    height: 375px; /* Maintain aspect ratio */
    border-radius: 30px 30px 0px 0px ; /* Apply border-radius to the image */
    
    object-fit: contain; /* Ensure the image covers the entire container */
  }
  .CardButton{
    /* background-color: aqua; */
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
  }
  .Card_Body{
   
    /* margin: 20px 10px 10px 10px ; */
    padding: 20px;
    height: 160px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }